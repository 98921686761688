:root {
  --bkg-green: #87ffc9;
  --bkg-blue: #134468;
  --bkg-cyan: #40fff2;
  --bkg-yellow: #ffcf55;
  --table-grey: #efefef;
  --accent-grey: hsl(0, 10%, 73%);
  --accent-green: hsl(164, 67%, 43%);
  --small-border: 3px;
  --medium-border: 5px;
  --large-border: 8px;
  --card-shadows-main: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
  --card-shadows-large: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);

  --primary-blue: #134468;
  --secondary-blue: #00355b;
  --primary-green: #88ffc8;
  --secondary-green: #57ffb1;
  --accent-green: #25ae88;
  --primary-yellow: #ffcf51;
  --secondary-yellow: #fbc025;
  --primary-cyan: #41fff2;
  --secondary-cyan: #1ef8e9;
  --accent-blue: #00c7b9;
  --accent-blue2: #05c1b4;
}

p,
h1,
h2,
h3,
h4 {
  color: var(--primary-blue) !important;
}

@font-face {
  font-family: "Muli" !important;
  src: URL("/public/assets/muli/Muli.ttf") format("truetype") !important;
}

input,
select {
  border: 1px solid #134568be !important;
  /* border-color: var(--primary-blue) !important; */
}

.c-sidebar {
  background-color: var(--primary-blue);
}

.sidebar-icon {
  padding: 8px 11px;
  margin-left: 5px;
  margin-right: 16px;
  background-color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.c-sidebar-nav {
  background: #134468 !important;
  color: #fff;
  background-color: var(--primary-blue) !important;
  background-image: url("./sidebar-bg.png") !important;
  background-size: 100% 70vh !important;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-attachment: fixed;
}

.c-sidebar .c-sidebar-brand {
  background: none;
}
.c-sidebar-nav-item,
.c-sidebar-nav-dropdown {
  margin: 15px 0;
}
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: #00355b !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background-color: #00355b8a !important;
}

.brand-type {
  color: #fff;
  background-color: #11d5b4;
  font-weight: 600;
}

.block-button-main {
  width: 100%;
  border: 0px;
  border-radius: var(--small-border);
  padding: 10px 20px !important;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary-blue);
}

.block-button-main:hover {
  background-color: var(--secondary-blue);
  color: #fff;
}

.main-primary-button {
  border: 0px;
  border-radius: var(--small-border);
  padding: 8px 20px;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary-blue);
}
.main-primary-button:hover {
  background-color: var(--secondary-blue);
  color: #fff;
}

.brand-type {
  margin-top: 5px;
  letter-spacing: 1.2px;
  background-color: var(--accent-green);
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: var(--small-border);
}
.page-title {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-blue);
  margin: 20px 0px;
}
.section-header-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-blue);
  margin: 30px 0px 10px;
}
.background-light-green {
  background-color: var(--bkg-green);
}
.text-theme-blue {
  color: var(--primary-blue);
}
/* .text- */
.card {
  border: none;
  background-clip: border-box;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}
.card-yellow {
  background-color: var(--primary-yellow) !important;
}
.card-green {
  background-color: var(--primary-green) !important;
}
.card-blue {
  background-color: var(--primary-blue) !important;
}
.card-cyan {
  background-color: var(--primary-cyan) !important;
}
.card-light-cyan {
  background-color: #e6fcff;
}
.card-blue-secondary {
  background-color: var(--accent-blue) !important;
}

.card-inner {
  display: inline-block;
  padding: 7px 20px;
  border-radius: var(--small-border);
  font-size: 13px;
  font-weight: 700;
}
.card-evacuation-text {
  font-size: 16px;
  font-weight: 600;
}
.card-evacuation-inner {
  background-color: var(--primary-green);
  border-radius: var(--small-border);
  width: 100%;
  font-weight: 600;
  margin-top: 5px;
  padding: 7px 20px;
  text-align: center;
}

.card-inner-yellow {
  background-color: var(--secondary-yellow);
}

.card-inner-blue {
  background-color: var(--secondary-blue);
}
.card-inner-green {
  background-color: var(--secondary-green);
}
.card-inner-cyan {
  background-color: var(--secondary-cyan);
}

.dashboard-card {
  /* min-height: 160px; */
}

.card-text-container {
  background-color: var(--primary-yellow);
  color: var(--primary-blue);
  padding: 5px 20px;
  display: inline-block;
  font-size: 1.3125rem;
  font-weight: 600;
  height: 40px;
  border-radius: var(--small-border);
  align-items: center;
  /* padding: 5px; */
}

.text-white {
  color: #fff !important;
}

.activity-card {
  padding-left: 40px 40px;
  text-align: left;
}
.activity-card button {
  background-color: #05c1b4;
  padding: 8px 40px;
  border-radius: var(--small-border);
  color: var(--primary-blue);
  text-transform: capitalize;
  border: 0px;
}

.last-evac-info {
  color: var(--primary-blue);
  font-weight: 600;
}
.last-evac-info span {
  background-color: var(--primary-green);
  padding: 6px 20px;
  border-radius: var(--small-border);
  margin-left: 5px;
}

.property-card-button {
  padding: 10px 20px 15px;
  background-color: var(--accent-green);
  border-radius: var(--medium-border);
  color: #fff;
}
.add-property-button {
  padding: 10px 20px 15px;
  background-color: #82fec8;
  border-radius: var(--medium-border);
  color: #fff;
}

.property-card-button img {
  color: #fff;
  transform: rotate(-90deg);
}
.rotated-go-icon {
  /* padding: 10px; */
  margin-top: 5px;
  /* background-color: #fff; */
  border-radius: 2px;
}
.rotated-go-icon img {
  color: var(--primary-blue);
  transform: rotate(-90deg);
}

.details-list {
  display: flex;
  text-align: left;
  padding: 10px 20px;
  border-bottom: 1px solid hsl(210, 11%, 70%);
}

.detail-type {
  flex: 2;
  font-weight: 500;
}
.detail-info {
  flex: 1;
  font-weight: 700;
  text-transform: capitalize;
}

.standard-card {
  background-color: #dbffff;
  padding: 10px 5px;
}

.premium-card {
  background-color: #ffcf51;
  padding: 10px 5px;
}

.price-card {
  display: flex;
  align-items: center;
  color: var(--primary-blue);
}

.price-text-container {
  flex: 2;
}
.premium-card .price-card-title {
  color: #4a00cc;
}
.price-card-title {
  font-size: 1.2rem;
  font-weight: 700;
}
.price-card-text {
  /* font-size: ; */
}
.standard-card .price-card-amount {
  background-color: #e6fcff;
  /* box-shadow: var(--card-shadows-main); */
}

.price-card-amount {
  font-weight: 700;
  padding: 10px 25px;
  background-color: #ffeab4;
  border-radius: var(--small-border);
  box-shadow: var(--card-shadows-main);
}

input[type="radio"] {
  -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
  -moz-appearance: checkbox; /* Firefox */
  -ms-appearance: checkbox; /* not currently supported */
  appearance: checkbox;
}

.operation-table .badge {
  padding: 10px;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-type {
  flex: 1;
}

.create-account {
  flex: 1;
}

.p4c-card {
  background-color: #25ae88;
}
.p4p-card {
  background-color: #82fec8;
}

.reg-type {
  padding: 7px;
  font-size: 12px;
  font-weight: 800;
  color: var(--primary-blue);
  border-radius: var(--small-border);
  background-color: #e6fcff;
  margin: 0px 15px;
  letter-spacing: 1px;
}
.reg-type-2 {
  padding: 7px;
  font-size: 12px;
  font-weight: 800;
  color: #e6fcff;
  border-radius: var(--small-border);
  background-color: var(--primary-blue);
  margin: 0px 15px;
  letter-spacing: 1px;
}
.login-label {
  color: var(--primary-blue) !important;
  font-weight: 500;
}
.login-title {
  font-weight: 800;
  letter-spacing: 0.56px;
  color: #25ae88 !important;
  text-align: center;
}
.login-policy-text {
  font-size: 14px;
  font-weight: 500;
  color: #bbb9b9 !important;
}
.login-policy-text span {
  color: var(--primary-blue) !important;
}

.register-hint {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #fff !important;
}
.register-hint span {
  color: #25ae88 !important;
}

.profile-detail-title {
  text-align: center;
  font-weight: 800;
  color: var(--primary-blue);
  margin: 20px 0px;
}
.details-form h6 {
  color: var(--primary-blue);
  font-weight: 700;
}
.details-form label {
  color: #949ba1;
}

/* reports page styles start */

.reports-card-icon {
  padding: 10px 15px 12px;
  background-color: #fff;
  border-radius: 3px;
}

.csr-amount {
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--secondary-green);
  border-radius: 2px;
  padding: 5px 20px;
}
.activity-header {
  background-color: #00ffdc;
  color: #fff;
  text-align: center;
  font-weight: 600;
  border: none;
}

/* .aside-container {
  position: sticky;
  width: 100%;
  top: 60px;
  height: 110vh;
} */
.aside-notification {
  margin-bottom: 30px;
  /* position: relative; */
  display: block;
  width: 100%;
  /* width: 330px; */
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 10px 10px 10px;
}
.aside-notification .card {
  margin: 5px;
  width: 100% !important;
}
.aside-notification .card-body {
  padding: 10px 20px !important;
}

.aside-notification2 {
  width: 100%;
}

.avatarText {
  background-color: #dbffef;
  color: #11d5b4;
  width: 55px;
  height: 55px;
  text-align: center;
  align-items: center;
  padding-top: 15px;
  /* vertical-align: middle; */
  /* padding: 10px; */
  border-radius: 50%;
  font-weight: 800;
  font-size: 16px;
  display: inline-block;
  /* margin-bottom: 20px; */
}
.avatar-name {
  color: #1b508f;
  text-transform: capitalize;
}

.reassign {
  font-weight: 500;
  padding: 4px 10px;
  color: #ff5125;
  background-color: #fff0ec;
  border-radius: 2px;
}

.dashTitle {
  display: inline-block;
  /* height: 40px; */
  /* background-size: cover; */
  background-image: url("/assets/leaf-logo.svg");
}

.dashBadge {
  text-transform: capitalize;
  padding: 7px 10px;
  border-radius: 4px;
  margin-left: 4px;
  background-color: #dbffef;
  color: #11d5b4;
  font-weight: 500;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.miniCard {
  background-color: #f2f9ff;
  /* padding: 20px; */
}
.sewageData {
  background-color: #f2f9ff;
}
.sewageData small {
  color: #515151;
  font-size: 10px;
  font-weight: 400;
}
.sewageData {
  font-size: 15px;
  font-weight: 700;
  color: var(--primary-blue);
}
.sewageTitle {
  display: inline-block;
  background-color: #d2ffeb;
  color: #11d5b4;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 20px;
}

.evacuationData {
  background-color: #f2f9ff;
}

.showSchedule {
  display: block;
  /* transition: top 2s ease-in-out;
  -webkit-transition: 2s top ease-in-out;
  -moz-transition: 2s top ease-in-out;
  -ms-transition: 2s top ease-in-out;
  -o-transition: 2s top ease-in-out; */
}
.dontShow {
  display: none;
  position: relative;
  transition: all 2s ease 0s;
  top: 0;
  /* transition: top 2s ease-in-out;
  -webkit-transition: 2s top ease-in-out;
  -moz-transition: 2s top ease-in-out;
  -ms-transition: 2s top ease-in-out;
  -o-transition: 2s top ease-in-out; */
}
.viewHeader {
  background-color: #00355b;
}
.viewHeader small {
  letter-spacing: 0.24px;
  color: #b5b5b5;
  align-items: center;
}
.details-type {
  color: #11d5b4;
  background-color: white;
  padding: 10px 10px;
  margin-top: -10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.viewHeader p {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
}

.details-view .miniCard {
  border: 1px solid #b5b5b5;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.details-view small {
  letter-spacing: 0.24px;
  color: #b5b5b5;
}
.details-view div {
  font-weight: 700;
  font-size: 14px;
}
.viewTitle {
  padding: 8px 20px;
  background-color: #fbfbfb;
  font-weight: 500;
  font-size: 14px;
  color: #134468;
  text-align: center;
}
.card-nav-link {
  text-decoration: none !important;
  cursor: pointer;
}
.spinLoder {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 100;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.aside-content small {
  margin-top: 10px;
  color: #b5b5b5;
}
.aside-content p {
  color: var(--primary-blue);
}
.aside-content .evacList {
  color: var(--primary-blue);
  padding: 6px 10px;
  background-color: #f9feff;
  /* display: inline-block; */
  border: 1px solid var(--secondary-blue);
  border-radius: var(--small-border);
  -webkit-border-radius: var(--small-border);
  -moz-border-radius: var(--small-border);
  -ms-border-radius: var(--small-border);
  -o-border-radius: var(--small-border);
}
.asideCard {
  position: sticky;
  top: 70px;
}

.walletBalance {
  display: inline;
  padding: 8px 20px;
  color: #00355b;
  background-color: #00ffdc;
}
.modal-bg-blue .close {
  color: #fff;
}

.modal-bg-blue {
  background-color: var(--primary-blue);
  color: white;
  font-weight: 600;
  align-items: center;
}

.tripSubData .partnerStyle {
  text-align: center;
  color: #fff;
  background-color: #00355b;
  font-weight: 600;
  border: 1px #b5b5b5 solid;
}
.tripSubData .evacStyle {
  color: white;
  background-color: #11d5b4;
  font-weight: 600;
}

.tripSubData small {
  color: #00355b;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}
.tripSubData div {
  padding: 8px 20px;
  display: inline-block;
}
.tripSubData .trips-total {
  color: #fff;
  background-color: #00355b;
  font-weight: 600;
  font-size: 14px;
}
.tripSubData .success-trips {
  color: white;
  background-color: #11d5b4;
  font-weight: 600;
  font-size: 14px;
}
.tripSubData .cancelled-trips {
  color: #ff0303;
  background-color: #fff0ec;
  font-weight: 600;
  font-size: 14px;
}

.subData small {
  color: #00355b;
  font-weight: 600;
  text-transform: capitalize;
}

.subData .sub-content {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #00355b;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #cefff8;
}

.addressContainer {
  position: relative;
  /* width: 200px; */
  /* display: flex; */
}
.addressList {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: white;
  list-style: none;
  border: 0.5px var(--primary-blue) solid;
  margin-top: -3px;
  margin-left: 0px;
  padding-left: 0px;
  border-radius: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.addressList li {
  padding: 5px;
  width: 100%;
  border-bottom: 0.5px var(--primary-blue) solid;
  cursor: pointer;
  padding-left: 20px;
}

.addressList li:hover {
  background-color: #b5b5b5 !important;
}

.card-header-blue {
  background-color: var(--primary-blue);
  color: #fff;
  font-weight: bold;
}

/* button style */
.button-bg-blue {
  background-color: var(--primary-blue);
  color: #fff;
}
.button-bg-blue:hover {
  color: #fff;
  background-color: #113e5f;
}

.button-bg-outline {
  background-color: #fff;
  color: var(--primary-blue);
  border: 1px solid #00355b;
}
.button-bg-outline:hover {
  color: var(--primary-blue);
  background-color: #05c1b4;
  border: 1px solid #fff;
}

/* account status styles  */
#status-container {
  text-align: center;
  display: block;
  padding: 5px;
  font-weight: 400;
  font-size: 10px;
  border-radius: 2px;
  text-transform: uppercase;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.status-success {
  color: #11d5b4;
  background-color: #d2ffeb;
}
.status-danger {
  color: #b2000096;
  background-color: #fff0ec;
}
.status-warning {
  background-color: #f1ffaa;
  color: #b26b00;
}

/* operations tables styles */
.account-type-container {
  background-color: #dbffef;
  color: #11d5b4;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
/* .badge .badge-{
  col
} */
.property-toggle {
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.property-toggle button {
  border: 1px solid transparent;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.property-toggle button:hover {
  border-color: #00c7b9;
}
.property-toggle .activate-button {
  background-color: #ebe7e7;
  color: #484848;
}

.property-toggle .suspend-button {
  background-color: #f1ffaa;
  color: #b26b00;
}

.property-toggle .deactivate-button {
  color: #b20000;
  background-color: #fff0ec;
}

.property-info small {
  color: #515151;
  font-size: 13px;
  font-weight: 400;
}

.property-info .info-content {
  color: #00355b;
  font-weight: bold;
  font-size: 14px;
}

.property-info .info-box {
  color: var(--primary-blue);
  background-color: #d8eeff;
  text-align: center;
  font-size: 10px;
  padding: 5px 10px;
}

.property-button {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  display: block;
  font-weight: 600;
}

/* sewage styles  */
.sewage-infos small {
  font-size: 12px;
  font-weight: 500;
  color: #515151;
  text-transform: capitalize;
}

.sewage-total-number {
  padding: 5px 12px;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  background-color: var(--primary-blue);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

/* nav tabs  */
.nav .nav-tabs {
  /* border-color: */
}

.nav-tabs {
}
.nav-tabs .nav-link {
  color: #00355b;
  font-weight: 600;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #11d5b4;
  font-weight: 600;
  border: 0px;
  border-bottom: 2.5px solid;
  border-color: #11d5b4;
  background-color: transparent;
}

.green-box {
  height: 10px;
  width: 10px;
  background-color: #25ae88;
  display: inline-block;
  margin-right: 5px;
}
.blue-box {
  height: 10px;
  width: 10px;
  background-color: var(--primary-blue);
  display: inline-block;
  margin-right: 5px;
}
.yellow-box {
  height: 10px;
  width: 10px;
  background-color: #ffcc00;
  display: inline-block;
  margin-right: 5px;
}
.red-box {
  height: 10px;
  width: 10px;
  background-color: #ff0000;
  display: inline-block;
  margin-right: 5px;
}

/* loader screen style */
.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255);
}

.loader-wrapper {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  animation: spin 2s ease-in-out infinite;
}

.link-style {
  text-decoration: none !important;
}
.link-style small {
  font-size: 14px;
  margin: 20px 0;
}

.card-free {
  background-image: url("../scss/customer-img.svg");
  background-position: center right 10px;
  background-size: 80px;
  background-repeat: no-repeat;
}

.c-badge-success {
  text-align: center;
  background-color: #dbffef;
  color: #11d5b4;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.c-badge-danger {
  text-align: center;
  background-color: #fff0ec;
  color: #ff0000;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.c-badge-primary {
  text-align: center;
  background-color: #def0ff;
  color: #134468;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}
.c-badge-secondary {
  text-align: center;
  background-color: #ebe7e7;
  color: #484848;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.c-badge-warning {
  text-align: center;
  background-color: #f8fece;
  color: #eeb809;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.c-badge-raw {
  text-align: center;
  background-color: #134468;
  color: #fff;
  text-transform: capitalize;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.admin-card-inner {
  background-color: #effff8;
  padding: 10px 30px;
  /* color: #11d5b4; */
  font-weight: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.admin-card-text {
  font-weight: 700;
  font-size: 16px;
  color: #1d5177;
}

.admin-card-green {
  background-color: #effff8;
  color: #11d5b4;
}

.admin-card-grey {
  background-color: #f5f5f5;
  color: #134468;
}
.admin-card-yellow {
  background-color: #ffffdb;
  color: #d58711;
}

.evacuation-title {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0px;
}

.evac-sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #949ba1;
}
.evac-sub-data {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-blue);
  margin-bottom: 10px;
}

.evac-price-box {
  padding: 10px 20px;
  background-color: var(--primary-blue);
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
/* evac price sidebar */
.aside-container-evac {
  position: sticky;
  width: 100%;
  top: 70px;
}
.aside-header {
  background-color: var(--bkg-blue);
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.priceBox {
  padding: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0px;
  border-radius: 2px;
  background-color: #25ae88;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.priceBoxBlue {
  padding: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0px;
  border-radius: 2px;
  font-size: 15px;
  background-color: var(--primary-blue);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.admin-card-form {
}
.admin-card-form label {
  font-size: 18px;
  font-weight: 600;
  color: #00355b;
}
.admin-card-form p {
  font-size: 14px;
  font-weight: 400;
  color: #00355b;
}
.admin-card-form button {
  color: #fff;
  background-color: var(--accent-green);
}
.admin-card-form button:hover {
  /* color: #fff; */
  opacity: 0.9;
  background-color: var(--accent-green);
}
/* .buttonload {
  background-color: #4caf50;
  border: none; 
  color: white;
  padding: 12px 16px;
  font-size: 16px; 
} */

.searchCard1 {
  padding: 20px;
}

.new-table td {
  padding-top: 30px !important;
}

.custom-select-new {
  display: block !important;
  width: 100%;
  border-radius: 5px;
  border: 1.2px solid #00355b !important;
  padding: 10px 20px !important;
  box-sizing: border-box;
  padding-right: 40px; /* Add extra space for the custom arrow */
  border: 1px solid #00355b;
  position: relative;
  -webkit-appearance: none;
  background-image: url(/caret-down.svg);
  background-position: 100%;
  background-repeat: no-repeat;
  appearance: none; /* Remove the default arrow */
  /* background: url("path-to-your-arrow-icon.svg") no-repeat right center;  */
  background-size: 12px; /* Size of the custom arrow */
  background-position: right 10px center; /* Adjust position */
}

.custom-select-new ::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.custom-select-new2 {
  display: block !important;
  width: 100%;
  border-radius: 10px;
  border: 1.2px solid #d0d5dd !important;
  padding: 10px 20px !important;
  box-sizing: border-box;
  padding-right: 40px; /* Add extra space for the custom arrow */
  /* border: 1px solid #00355b; */
  position: relative;
  -webkit-appearance: none;
  background-image: url(/caret-down.svg);
  background-position: 100%;
  background-repeat: no-repeat;
  appearance: none; /* Remove the default arrow */
  /* background: url("path-to-your-arrow-icon.svg") no-repeat right center;  */
  background-size: 12px; /* Size of the custom arrow */
  background-position: right 10px center; /* Adjust position */
}

.custom-select-new2 ::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.custom-dropdown-btn {
  background-color: #00355b !important;
  color: #fff !important;
  display: block !important;
  width: 100%;
  height: 45px;
  min-width: 250px;
  border-radius: 10px;
}

.custom-dropdown-btn > .btn::after {
  content: "+" !important;
}

.ddp .dropdown-toggle::after {
  display: none !important;
}

.custom-input-new {
  display: block !important;
  width: 100%;
  border-radius: 5px;
  border: 1.2px solid #1d5177 !important;
  padding: 10px 20px !important;
  box-sizing: border-box;
}

.custom-select-new {
  display: block !important;
  width: 100%;
  border-radius: 5px;
  border: 1.2px solid #1d5177 !important;
  padding: 10px 20px !important;
  box-sizing: border-box;
  padding-right: 40px; /* Add extra space for the custom arrow */
  border: 1px solid #1d5177;
  position: relative;
  -webkit-appearance: none;
  background-image: url(/caret-down.svg);
  background-position: 100%;
  background-repeat: no-repeat;
  appearance: none; /* Remove the default arrow */
  /* background: url("path-to-your-arrow-icon.svg") no-repeat right center;  */
  background-size: 12px; /* Size of the custom arrow */
  background-position: right 10px center; /* Adjust position */
}

.custom-select-new ::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}
